<template>
  <template v-if="loaded_status">
    <div>
      <div class="card shadow mb-7">
        <div class="card-body pt-6 pb-0">
          <div class="d-flex overflow-auto h-55px">
            <ul
              class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
            >
              <li
                class="nav-item"
                v-for="(status, index) in lookupStatus"
                :key="index"
              >
                <!-- {{ status }} -->
                <span
                  style="cursor: pointer"
                  aria-current="page"
                  href="/societa/dettaglio-societa/anagrafica-societa/8625"
                  class="router-link-exact-active nav-link text-active-primary me-1 fs-6 text-uppercase"
                  :class="payload.id_tab == status.id ? 'active' : ''"
                  @click="
                    setIdStatus(status.id);
                    payload.id_tab = status.id;
                    payload.rowstoskip = 0;
                    id_year && id_event ? get_invitations() : '';
                  "
                  v-html="status.label"
                >
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <FiltersInvitations
        @getList="get_invitations_filters"
        @resetFilters="resetFilters"
        :loaded="loaded_invitations"
        @setPayload="setPayload"
        :payload="payload"
        :pressedSearch="pressedSearch"
        @pressedSearch="pressedSearch = true"
      />
      <TableInvitations
        @getList="get_invitations"
        @resetFilters="resetFilters"
        :objectList="invitations"
        :loaded="loaded_invitations"
        :status="status_invitations"
        @setPayload="setPayload"
        :payload="payload"
        :pressedSearch="pressedSearch"
        :id_year="id_year"
        :id_event="id_event"
      />
    </div>
  </template>
  <Loader v-else />
</template>
<script>
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/functions/trimInput";
import FiltersInvitations from "../components/invitations/FiltersInvitations.vue";
import TableInvitations from "../components/invitations/TableInvitations.vue";
import { useApiCall } from "@/composables/apiCall";
import { API } from "@/enums/costanti";
import { useStore } from "vuex";
export default defineComponent({
  name: "sanzioni",
  components: {
    FiltersInvitations,
    TableInvitations,
  },
  setup() {
    const pressedSearch = ref(false);
    const store = useStore();
    const id_year = computed(() => store.getters.id_year);
    const id_event = computed(() => store.getters.id_event);
    const payload = ref({
      // name: "string",
      // surname: "string",
      // date_of_birth: "",
      // tax_code: "",
      // phone_number: "string",
      // email: "string",
      // photo: "string",
      // id_language: 0,
      id_person: null,
      // id_tab: localStorage.getItem("id_tab")
      //   ? parseInt(localStorage.getItem("id_tab"))
      //   : 1,
      id_year: id_year.value,
      id_event: id_event.value,

      rowstoskip: 0,
      fetchrows: 25,
      id_tab: localStorage.getItem("id_tab")
        ? parseInt(localStorage.getItem("id_tab"))
        : 1,
    });
    const setPayload = ({ name, value }) => {
      payload.value[name] = value;
    };
    const resetFilters = () => {
      payload.value = {
        id_event: id_event.value,
        id_year: id_year.value,
        sortcolumn: "",
        sortorder: "",
        rowstoskip: 0,
        fetchrows: 25,
        // id_tab: payload.value.id_tab ? payload.value.id_tab : 2,
        id_tab: payload.value.id_tab ? payload.value.id_tab : 1,
      };
      get_invitations();
      pressedSearch.value = false;
    };

    const {
      element: invitations,
      loaded: loaded_invitations,
      getElement: get_invitations,
      status: status_invitations,
    } = useApiCall(API.INVITATIONS_LIST, payload);
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Inviti", payload);
      if (payload.value.id_year && payload.value.id_event)
        await get_invitations();
    });
    watch([id_year, id_event], async () => {
      payload.value.id_year = id_year.value;
      payload.value.id_event = id_event.value;
      if (id_year.value && id_event.value) {
        await get_invitations();
      }
    });

    const {
      element: lookups,
      getElement: get_lookups,
      loaded: loaded_status,
    } = useApiCall(API.LOOKUP_GET, { keys: "it" });
    get_lookups();

    const setIdStatus = (id) => {
      payload.value.id_tab = id;
      localStorage.setItem("id_tab", id);
    };

    const get_invitations_filters = () => {
      payload.value.rowstoskip = 0;
      get_invitations();
    };

    return {
      get_invitations_filters,
      setIdStatus,
      loaded_status,
      lookups,
      API,
      resetFilters,
      setPayload,
      get_invitations,
      invitations,
      loaded_invitations,
      status_invitations,
      trimInput,
      payload,
      pressedSearch,
      id_event,
      id_year,
    };
  },
  computed: {
    lookupStatus() {
      return this.lookups ? this.lookups.result[0].invitation_tabs : [];
    },
  },
});
</script>
