<template>
  <h1 class="badge" :style="`background-color: ${bg_color};`">
    {{ label }}
  </h1>
</template>
<script>
export default {
  name: "EtichettaGruppo",
  props: {
    bg_color: {
      type: String,
    },
    label: {
      type: String,
    },
  },
};
</script>
