<template>
  <div class="card pb-3 mt-0 shadow mb-3">
    <div class="card-body">
      <div class="">
        <div class="row" v-if="loaded_guest && status_guest == 200">
          <div class="align-items-center mb-2">
            <h1 class="align-items-center fw-bolder my-2 fs-3">
              DATI ACCOMPAGNATORE
              <i
                v-if="!guest.id_person"
                class="bi bi-pen text-dark fs-4"
                data-bs-toggle="modal"
                :data-bs-target="
                  guest.id_person
                    ? '#kt_modal_edit_guest'
                    : '#kt_modal_add_invitation_guest'
                "
                style="cursor: pointer"
                @click="selected_id_person_guest = guest.id_person"
              ></i>
            </h1>
          </div>

          <div class="row">
            <div class="pt-2 col-5">
              <span class="fw-bold text-gray-800">COGNOME, NOME: </span>
            </div>
            <div class="pt-2 pe-0 col-7">
              <input
                class="form-control"
                name="namesurnameguest"
                :value="name_surname_props"
                disabled
              />
              <Field type="hidden" name="name_guest" disabled />
              <Field type="hidden" name="surname_guest" disabled />
              <ErrorMessage name="surname" />
            </div>
          </div>
          <div class="row">
            <div class="pt-2 col-5">
              <span class="fw-bold text-gray-800">DATA DI NASCITA: </span>
            </div>
            <div class="pt-2 pe-0 col-7">
              <input
                class="form-control"
                :value="formatDateITANoTime(guest.date_of_birth)"
                disabled
              />
              <Field type="hidden" name="date_of_birth_guest" disabled />
            </div>
          </div>
          <div class="row">
            <div class="pt-2 col-5">
              <span class="fw-bold text-gray-800">EMAIL:</span>
            </div>
            <div class="pt-2 pe-0 col-7">
              <input
                class="form-control"
                :value="guest.email"
                name="email_guest"
                disabled
              />
              <Field
                class="form-control"
                type="hidden"
                :value="guest.email"
                name="email_guest"
                disabled
              />
            </div>
          </div>
          <div class="row">
            <div class="pt-2 col-5">
              <span class="fw-bold text-gray-800">TELEFONO:</span>
            </div>
            <div class="pt-2 pe-0 col-7">
              <input
                class="form-control"
                :value="guest.phone_number"
                name="phone_number_guest"
                disabled
              />
              <Field
                class="form-control"
                type="hidden"
                :value="guest.phone_number"
                name="phone_number_guest"
                disabled
              />
            </div>
          </div>
          <div class="row">
            <div class="pt-2 col-5">
              <span class="fw-bold text-gray-800">PAGANTE:</span>
            </div>
            <div class="pt-2 pe-0 col-7">
              <span class="text-success" v-if="guest.self_payment">SI</span>
              <span class="text-danger" v-else>NO</span>
            </div>
          </div>
          <ModalEditGuest
            :id_person="selected_id_person_guest"
            @getList="
              $emit('getList');
              get_session_seats();
              get_session_people();
            "
          />
        </div>
        <div
          v-else-if="loaded_guest && status_guest != 200"
          class="mx-auto my-auto text-center pt-5 mt-5 mb-5 pb-5"
        >
          <span class="text-gray-700 ps-3 fs-2">
            {{
              customNoElementMessage
                ? customNoElementMessage
                : "Attenzione! Nessun risultato."
            }}
          </span>
        </div>
        <div class="mx-auto my-auto text-center" v-else>
          <div class="spinner-border text-gray-600" role="status"></div>
          <span class="text-gray-600 ps-3 fs-2">Loading</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { formatDateITANoTime } from "@/functions/formatDate";
import { Field, ErrorMessage } from "vee-validate";
import ModalEditGuest from "@/components/invitations/ModalEditGuest.vue";

export default defineComponent({
  name: "qr",
  emits: ["getList"],
  props: {
    guest: {
      type: Object,
    },
    name_surname_props: {
      type: String,
    },
    loaded_guest: {
      type: Boolean,
    },
    status_guest: {
      type: Number,
    },
    idx_guest: { type: Number },
    // isInvitato:{
    //   boolean
    // }
  },
  components: {
    Field,
    ErrorMessage,
    ModalEditGuest,
    // Form,
  },
  setup() {
    const selected_id_person_guest = ref(0);
    return {
      selected_id_person_guest,
      formatDateITANoTime,
    };
  },
  computed: {
    name_surname() {
      return `${this.guest.surname}, ${this.guest.name}`;
    },
  },
});
</script>
