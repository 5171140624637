<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getList')">
          <div class="row mb-4">
            <div class="col-md-4 mb-4 filter">
              <InputList
                :apiLink="API.LOOKUP_PERSON_SEARCH"
                payloadParameterTextName="surname"
                payloadParameterTextNameSec="name"
                payloadParameterTextNameTer="date_of_birth"
                parameterToShow="nome"
                @isSelectedElement="
                  $emit('setPayload', {
                    name: 'id_person',
                    value: $event.id,
                  })
                "
                @cancelElement="
                  $emit('setPayload', {
                    name: 'id_person',
                    value: null,
                  })
                "
                placeholder="Persona"
                getEntireRecord
                :emitEventOnCancel="false"
              />
            </div>
            <div class="col-sm-4 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Cognome"
                aria-label=""
                :value="payload.surname"
                @input="
                  $emit('setPayload', {
                    name: 'surname',
                    value: $event.target.value,
                  })
                "
              />
            </div>
            <div class="col-sm-4 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Nome"
                aria-label=""
                :value="payload.name"
                @input="
                  $emit('setPayload', {
                    name: 'name',
                    value: $event.target.value,
                  })
                "
              />
            </div>
            <!-- <div class="col-md-6 filter">
              <select
                class="form-select"
                @change="
                  $emit('setPayload', {
                    name: 'id_status',
                    value: parseInt($event.target.value),
                  })
                "
                :value="payload.id_status"
              >
                <option selected :value="0">Status</option>
                <option
                  v-for="sessione in lookupStatus"
                  :key="sessione.id"
                  :value="sessione.id"
                >
                  {{ sessione.label }}
                </option>
              </select>
            </div> -->
          </div>

          <div class="text-center mt-5">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                !pressedSearch ? $emit('pressedSearch') : '';
                $emit('getList');
              "
              :disabled="pressedSearch && !loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('resetFilters')"
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { format } from "@/functions/formatDate";
import { useApiCall } from "@/composables/apiCall";
import { API } from "@/enums/costanti";
import InputList from "../utility/InputList.vue";
export default defineComponent({
  name: "filters-people",
  emits: ["resetFilters", "getList", "setPayload"],
  props: {
    loaded: {
      type: Boolean,
    },
    payload: { type: Object },
    pressedSearch: {
      type: Boolean,
    },
  },
  components: { InputList },
  setup() {
    return {
      format,
      API,
    };
  },
});
</script>

<style></style>
